import React from "react";
import Main from "components/Layout/main";
import StaticPageMeta from "../components/UI/staticPageMeta";

const NotFoundPage = () => {
    const gtmData = {
        page: {
            title: "Not found",
            type: "Static",
        },
    };

    return (
        <>
            <StaticPageMeta pageName="404"/>
            <Main gtmData={gtmData}>
                <h1 className="typography__h1">404: Not Found</h1>
                <p className="typography__p">You just hit a route that doesn&#39;t exist... the sadness.</p>
            </Main>
        </>
    );
};

export default NotFoundPage;
